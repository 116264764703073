<template>
    <div>
        <div class="pt-4 text-gray-700 border-t border-dashed">
            <h3 class="">Connect to your exchange</h3>
            <div class="mt-2">
                <div class="sm:block">
                    <nav class="flex space-x-4" aria-label="Tabs">
                        <!-- Current: "bg-gray-100 text-gray-700", Default: "text-gray-500 hover:text-gray-700" -->
                        <div class="cursor-pointer text-gray-500 px-3 py-4 font-medium text-sm rounded-md border-2 border-transparent" 
                            :class="{ 'border-indigo-500': activePlatform == 'binance'}"
                            aria-current="page"
                            @click="switchTab('binance')"
                        >
                            <img 
                                :src="require(`@/assets/binance.png`)" 
                                :class="{ 'grayscale': activePlatform !== 'binance'}"
                            />
                        </div>
                        <!-- <div class="cursor-pointer text-gray-500 px-3 py-4 font-medium text-sm rounded-md border-2 border-transparent" 
                            :class="{ 'border-indigo-500': activePlatform == 'coinbase'}"
                            aria-current="page"
                            @click="switchTab('coinbase')"	
                        > -->
                        <div class="text-gray-500 px-3 py-4 font-medium text-sm rounded-md border-2 border-transparent" 
                            :class="{ 'border-indigo-500': activePlatform == 'coinbase'}"
                            aria-current="page"	
                        >
                            <img 
                                :src="require(`@/assets/coinbase.png`)" 
                                class=""
                                :class="{ 'grayscale': activePlatform !== 'coinbase'}"
                            /> 
                        </div>
                        <!-- <div class="cursor-pointer text-gray-500 px-3 py-4 font-medium text-sm rounded-md border-2 border-transparent" 
                            :class="{ 'border-indigo-500': activePlatform == 'bitkub'}"
                            aria-current="page"
                            @click="switchTab('bitkub')"
                        > -->
                        <div class="text-gray-500 px-3 py-4 font-medium text-sm rounded-md border-2 border-transparent" 
                            :class="{ 'border-indigo-500': activePlatform == 'bitkub'}"
                            aria-current="page"
                        >
                            <img 
                                :src="require(`@/assets/bitkub.png`)" 
                                class=""
                                :class="{ 'grayscale': activePlatform !== 'bitkub'}"
                            /> 
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div v-if="activePlatform == 'binance'" class="mt-4 py-4 text-gray-800 border-t border-b border-dashed">
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Binance
                </h3>
            </div>
            <div class="my-2">
                <div class="flex">
                    <div class="w-1/4">
                        <label for="apiKey" class="block text-sm font-medium text-gray-700 mt-3">
                            API Key
                        </label>
                    </div>
                    <div class="w-3/4">
                        <div class="mt-1">
                            <input type="text" required name="apiKey" autocomplete="off" v-model="platforms.binance.apiKey" value="" class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300">
                        </div>
                    </div>
                </div>
                <div class="flex mt-3">
                    <div class="w-1/4">
                        <label for="secretKey" class="block text-sm font-medium text-gray-700 mt-3">
                            Secret Key
                        </label>
                    </div>
                    <div class="w-3/4">
                        <div class="mt-1">
                            <input type="text" required autocomplete="off" name="secretKey" v-model="platforms.binance.secretKey" class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activePlatform == 'coinbase'" class="mt-4 py-4 text-gray-800 border-t border-b border-dashed">
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Coinbase
                </h3>
            </div>
            <div class="my-2">
                <div class="flex">
                    <div class="w-1/4">
                        <label for="apiKey" class="block text-sm font-medium text-gray-700 mt-3">
                            API Key
                        </label>
                    </div>
                    <div class="w-3/4">
                        <div class="mt-1">
                            <input type="text" required name="apiKey" autocomplete="off" v-model="platforms.coinbase.apiKey" value="" class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activePlatform == 'bitkub'" class="mt-4 py-4 text-gray-800 border-t border-b border-dashed">
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Bitkub
                </h3>
            </div>
            <div class="my-2">
                <div class="flex">
                    <div class="w-1/4">
                        <label for="apiKey" class="block text-sm font-medium text-gray-700 mt-3">
                            API Key
                        </label>
                    </div>
                    <div class="w-3/4">
                        <div class="mt-1">
                            <input type="text" required name="apiKey" autocomplete="off" v-model="platforms.bitkub.apiKey" value="" class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300">
                        </div>
                    </div>
                </div>
                <div class="flex mt-3">
                    <div class="w-1/4">
                        <label for="secretKey" class="block text-sm font-medium text-gray-700 mt-3">
                            Secret Key
                        </label>
                    </div>
                    <div class="w-3/4">
                        <div class="mt-1">
                            <input type="text" required autocomplete="off" name="secretKey" v-model="platforms.bitkub.secretKey" class="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-3 text-right">
            <div class="text-right">
                <button 
                    @click="handleSubmitCredentials" 
                    type="submit" 
                    class="ml-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                    :class="{ 'bg-blue-300': isFormLoading }">
                        {{ (isFormLoading) ? "Loading..." : "Save" }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
	name: "exchanges",
    props: ["user", "showTemplate"],
	mounted(){
		this.platforms = this.user.platforms;
	},
	data: function () {
		return {
			activePlatform: "binance", // binance | coinbase | bitkub
			platforms: {},
            isFormLoading: false,
            activeSource: "exchange", // exchange | manual
		};
	},
	methods: {
		switchTab: async function(tab){
			this.activePlatform = tab;
		},
		handleSubmitCredentials: async function(){
            this.isFormLoading = true;
			const accessToken = await this.$auth.getTokenSilently();
            try{
                const { data } 	  = await axios.put(`/api/users`, { platforms: {...this.platforms} }, { headers: { Authorization: `Bearer ${accessToken}`} });
                if(data.user){
                    this.$emit('handle-show-template', true);
                    this.$emit('handle-updated-user', data.user);
                    this.$toast.open({
                        message: "Information updated successfully!",
                        type: "success",
                        position: "bottom",
                        duration: 5000
                    });
                }else{
                    this.$emit('handle-show-template', false);
                    this.$toast.open({
                        message: "There was an issue updating the information.",
                        type: "error",
                        position: "bottom",
                        duration: 5000
                    });
                }
            }catch(error){
                this.$toast.open({
                    message: error.response.data.message || "There was an issue updating the information.",
                    type: "error",
                    position: "bottom",
                    duration: 5000
                });
			}finally{
                this.isFormLoading = false;
            }
		}
	}
};
</script>

<style scoped>
	.grayscale{
		filter: grayscale(1);
	}
</style>