import Vue from "vue";
import App from "./App.vue";
import Axios from "./plugins/axios";
import router from "./router";
import { Auth0Plugin } from "./auth";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import { domain, clientId, audience } from "../auth_config.json";
import "./assets/tailwind-dev.css";

Vue.config.productionTip = false;

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});



Vue.use(VueToast);
Vue.use(Axios);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
