<template>
    <div>
        <h2 class="text-2xl leading-7 font-semibold">
            Select a plan
        </h2>
        <p class="mt-3 text-gray-600">
            Pricing based on your holdings and needs
        </p>
        <span v-if="user.subscription">
            <form action="/api/plans/customer-portal" method="POST" v-if="user.subscription.stripeSubscriptionId">
                <input type="hidden" name="customerId" :value="user.subscription.stripeCustomerId" />
                <button class="text-indigo-600 underline" type="submit">Cancel/Update Card</button>
            </form>
        </span>
        <div class="mt-4 py-4 text-gray-800 border-t border-b border-dashed">
            <div class="lg:block" v-if="plans">
                <table class="w-full h-px table-fixed">
                    <thead>
                        <tr>
                            <th class="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
                                <span>Plans</span>
                            </th>
                            <th v-for="plan in plans" :key="plan._id" class="w-1/4 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left" scope="col">
                                {{plan.planName}}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="border-t border-gray-200 divide-y divide-gray-200">
                        <tr>
                            <th class="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top" scope="row">Pricing</th>
                            
                            <td class="h-full py-8 px-6 align-top" v-for="plan in plans" :key="plan._id">
                                <div class="relative h-full table w-full">
                                    <p>
                                        <span class="text-3xl font-extrabold text-gray-900">{{plan.priceAlias}}</span>
                                        <span class="text-base font-medium text-gray-500">/mo</span>
                                    </p>
                                    <!-- <p class="mt-4 mb-16 text-sm text-gray-500">Quis suspendisse ut fermentum neque vivamus non tellus.</p> -->
                                    <!-- <a href="#" class="
                                        bottom-0 flex-grow block w-full rounded-md 5 py-2 
                                        text-sm font-semibold text-white text-center hover:bg-gray-900" 
                                            :class="{ 'bg-gray-300': user.planId == plan.planId, 
                                            'bg-gray-700': user.planId !== plan.planId }">
                                        Buy {{plan.alias}}
                                    </a> -->
                                    <form action="/api/plans/create-checkout-session" method="POST">
                                        <input type="hidden" name="userId" :value="user._id" />
                                        <input type="hidden" name="priceId" :value="plan.stripePriceId" />
                                        <span v-if="user.planId == plan.planId" class="font-bold text-blue-600">Current Plan</span>
                                        <span v-else><button type="submit" class="bottom-0 flex-grow block w-full rounded-md 5 py-2 
                                        text-sm font-semibold text-white text-center hover:bg-gray-900" 
                                            :class="{ 'bg-gray-300': user.planId == plan.planId, 
                                            'bg-gray-700': user.planId !== plan.planId }">
                                              Buy
                                        </button></span>  
                                    </form>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th class="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left" colspan="4" scope="colgroup">Features</th>
                        </tr>
                        <tr v-for="(feature, index) in plans[0].features" :key="index">
                            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">{{feature.name}}</th>
                                <td class="py-5 px-6" v-for="plan in plans" :key="plan._id" >
                                    
                                    <span v-if="plan.features[index].type == 'string'">{{plan.features[index].value}}</span>
                                    <span v-if="plan.features[index].type == 'number'">{{plan.features[index].value}}</span>
                                    <span v-if="plan.features[index].type == 'boolean'">
                                        <CheckIcon v-if="plan.features[index].value == true" />
                                        <CancelIcon v-else />
                                    </span>
                                </td>
                        </tr>                    
                    </tbody>
                    
                    <tfoot>
                        <tr>
                            <th class="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top" scope="row"></th>
                            <td class="h-full py-8 px-6 align-top" v-for="plan in plans" :key="plan._id">
                                <div class="relative h-full table w-full">
                                    <p>
                                        <span class="text-3xl font-extrabold text-gray-900">{{plan.priceAlias}}</span>
                                        <span class="text-base font-medium text-gray-500">/mo</span>
                                    </p>
                                    <form action="/api/plans/create-checkout-session" method="POST">
                                        <input type="hidden" name="userId" :value="user._id" />
                                        <input type="hidden" name="priceId" :value="plan.stripePriceId" />
                                        <span v-if="user.planId == plan.planId" class="font-bold text-blue-600">Current Plan</span>
                                        <span v-else><button type="submit" class="bottom-0 flex-grow block w-full rounded-md 5 py-2 
                                        text-sm font-semibold text-white text-center hover:bg-gray-900" 
                                            :class="{ 'bg-gray-300': user.planId == plan.planId, 
                                            'bg-gray-700': user.planId !== plan.planId }">
                                              Buy
                                        </button></span>  
                                    </form>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>



<script>

import CheckIcon from "../components/icon/SolidCheck";
import CancelIcon from "../components/icon/Cancel";

import axios from "axios";

export default {
	name: "subscription",
	props: ["user"],
    components: {
		CheckIcon,
        CancelIcon
	},
    mounted() {
		this.$nextTick(() => {
			this.getPlans();
            this.checkIfPlanUpdated();
		});
	},
	data: function () {
		return {
            plans: null
		};
	},
	methods: {
        async getPlans(){
            const accessToken = await this.$auth.getTokenSilently();
			const { data } = await axios.get(`/api/plans`, { headers: { Authorization: `Bearer ${accessToken}`} } );
            console.log(data);
			this.plans = data;
		},
        async checkIfPlanUpdated(){
            const { session_id } = this.$route.query;
            if(session_id){
                //remove session id from url
                this.$router.push(this.$route.path);
                this.$toast.open({
					message: "Plan updated successfully!",
					type: "success",
					position: "bottom",
					duration: 5000
				});
            }
        }
	}
};
</script>