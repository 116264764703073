<template>
    <div>
        <h2 class="text-2xl leading-7 font-semibold">
            Welcome to MioPort!
        </h2>
        <p class=" text-xl mt-1 text-gray-600">
            Setup your Portfolio sheet
        </p>
     
        <div class="pt-4 text-gray-700">

            <div class="mt-0 pt-4 pb-4 text-gray-700 border-t pt-4 border-b border-dashed" v-if="showTemplate == true || user.sheetId">
                <h2 class="text-2xl leading-7 font-semibold">
                    Your Sheets
                </h2>
                <div class="rounded-md bg-blue-50 p-2 mt-3">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: solid/information-circle -->
                            <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                            </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p class="text-sm text-blue-700">
                                Make sure to use <strong>{{user.email}}</strong> when giving Mioport permissions.
                            </p>
                        </div>
                    </div>
                </div>    
                <div class="mt-2 text-gray-700">
                    <div class="">
                        <!-- Access Connected Sheet -->
                        <div>
                            <a :href="`https://docs.google.com/spreadsheets/d/${user.sheetId}/edit`" target="_blank" class="mt-2 inline-flex items-center px-5 text-gray-700 py-6 border shadow-sm text-base font-medium rounded-md hover:shadow-md bg-white focus:outline-none border-gray-200">
                                <img src="https://cdn-icons-png.flaticon.com/512/2965/2965327.png" alt="" width="30" class="mr-2">
                                    Go To Sheet
                                <svg xmlns="http://www.w3.org/2000/svg" class="ml-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="pt-3 flex" v-if="activeSource !== 'exchange'">
                <a href="#" @click="handleChangeActiveSource('exchange')" class="w-full border-2 border-gray-300 hover:border-indigo-600 text-left text-gray-500 hover:text-gray-700 p-5 font-medium text-sm rounded-md">
                    Pull my assets and trades automatically
                </a>
            </div>
            <div class="" v-if="activeSource == 'exchange'">
                <Exchanges :user="user" @handle-show-template="handleShowTemplate" @handle-updated-user="handleUpdatedUser" />
            </div>
            
             
        </div>

        <div class="rounded-md bg-blue-50 p-2 mt-3" v-if="generatingSheetTemplate">
            <div class="flex">
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700">
                        Generating sheet template...
                    </p>
                </div>
            </div>
        </div>
        
        <div class="pt-3 flex" v-if="activeSource !== 'manual'">
            <a href="#" @click="handleChangeActiveSource('manual')" class="w-full border-2 border-gray-300 hover:border-indigo-600 text-left text-gray-500 hover:text-gray-700 p-5 font-medium text-sm rounded-md">
                I'll add coins manually
                <br/><span class="font-normal text-gray-400">Click to generate a template sheet.</span>
            </a>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import Exchanges from "@/components/Exchanges";

export default {
	name: "onboard",
	props: ["user"],
    components: {
		Exchanges
	},
    mounted(){
		this.platforms = this.user.platforms;
	},
    data: function () {
		return {
			platforms: {},
            activeSource: "exchange", // exchange | manual
            activePlatform: "binance", // binance | coinbase | bitkub
            showTemplate: false,
            sheetsTemplateFile: "https://docs.google.com/spreadsheets/d/1WmhR9LlGLNb1OPbGVCZ1NpNuh3HYTY34mhIGd2lJPwQ/copy",
            generatingSheetTemplate: false
        };
	},
    computed: function () {
        
    },
    methods: {
		// handleSubmitCredentials: async function(){
        //     this.isFormLoading = true;
		// 	const accessToken = await this.$auth.getTokenSilently();
        //     try{
        //         const { data } = await axios.put(`/api/users`, { platforms: {...this.platforms}, timezone: this.timezone }, { headers: { Authorization: `Bearer ${accessToken}`} });
        //         if(data.user){
        //             this.showTemplate = true;
        //             console.log(data.user.sheetId);
        //             this.user = data.user;
        //             this.$toast.open({
        //                 message: "Information updated successfully!",
        //                 type: "success",
        //                 position: "bottom",
        //                 duration: 5000
        //             });
        //         }
        //     }catch(error){
        //         this.showTemplate = false;
        //         this.$toast.open({
        //             message: error.response.data.message || "There was an issue updating the information.",
        //             type: "error",
        //             position: "bottom",
        //             duration: 5000
        //         });
        //     }finally{
        //         this.isFormLoading = false;
        //     }
		// },
        generateNewSheetTemplate: async function(){
            this.generatingSheetTemplate = true;
            const accessToken = await this.$auth.getTokenSilently();
            try{
                const { data } = await axios.get(`/api/users/create-sheet-template`, { headers: { Authorization: `Bearer ${accessToken}`} });
                if(data.user){
                    this.user = data.user;
                    this.$toast.open({
                        message: "Sheet template created successfully!",
                        type: "success",
                        position: "bottom",
                        duration: 5000
                    });
                }
            }catch(error){
                this.$toast.open({
                    message: error.response.data.message || "There was an issue creating the sheet.",
                    type: "error",
                    position: "bottom",
                    duration: 5000
                });
            }finally{
                this.generatingSheetTemplate = false;
            }
        },
        handleShowTemplate: function(value){
            this.showTemplate = value;
        },
        handleUpdatedUser: function(value){
            this.$emit('handle-updated-user', value);
        },
        handleChangeActiveSource: async function(source){
            if(this.activeSource !== source){
                this.handleShowTemplate();
            }
            if(source == "manual" && !this.user.sheetId){
                this.generateNewSheetTemplate();
            }
            this.activeSource = source;
        }
	}

};
</script>