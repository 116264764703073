<template>
	<div class="relative flex  min-h-screen bg-gray-100 sm:pt-0">
		<div class="md:w-1/2 mx-auto md:px-6 lg:px-8 mt-24 p-1">
			<Logo />
			<Navigation />
			<div v-if="user !== null" class="mt-4 bg-white overflow-hidden shadow sm:rounded-lg p-6">
				<!-- <Account :user="user" /> -->
				
				<Onboard :user="user" @handle-updated-user="handleUpdatedUser" />
				<!-- <Sheets :user="user" /> -->
				
			</div>
			<div class="flex justify-center pt-4 space-x-2"></div>
			<div class="text-gray-400 text-center text-sm">
				All Rights Reserved. MioPort. 2022.
			</div>
			
		</div>
	</div>
</template>

<script>

import Logo from "../components/Logo.vue";
// import Account from "../components/Account";

import Navigation from "../components/Navigation";
import Onboard from "../components/Onboard";
import axios from "axios";
// import Sheets from '../components/Sheets.vue';

export default {
	name: "home",
	components: {
		Logo,
		// Account,
		Navigation,
		Onboard,
		// Sheets
	},
	mounted() {
		this.$nextTick(() => {
			this.getUserAccount();
		});
	},
	data: function () {
		return {
			activeTab: "Account",
			user: null
		};
	},
	methods: {
		async getUserAccount(){
			const accessToken = await this.$auth.getTokenSilently();
			const { data } = await axios.get(`/api/users?name=${this.$auth.user.name}&email=${this.$auth.user.email}&picture=${this.$auth.user.picture}`, { headers: { Authorization: `Bearer ${accessToken}`} } );
			this.user = data.user;
		},
		handleUpdatedUser: function(value){
			this.user = value;
        },
	}
};
</script>
