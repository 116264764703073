<template>
	<div class="relative flex bg-gray-100 sm:pt-0 min-h-screen">
		<div class="md:w-1/2 mx-auto md:px-6 lg:px-8 mt-24 p-1">
			<Logo />
			<Navigation />
			<div class="mt-4 bg-white overflow-hidden shadow sm:rounded-lg p-6">
				<Connects :user="user" v-if="user" />
			</div>
			<div class="flex justify-center pt-4 space-x-2"></div>
			<div class="text-gray-400 text-center text-sm">
				All Rights Reserved. MioPort. 2022.
			</div>
		</div>
	</div>
</template>

<script>

import Logo from "../components/Logo.vue";
import Navigation from "../components/Navigation";
import Connects from "../components/Connects";

import axios from "axios";

export default {
	name: "connect",
	components: {
		Logo,
		Connects,
		Navigation
	},
	mounted() {
		this.$nextTick(() => {
			this.getUserAccount();
		});
	},
	data: function () {
		return {
			user: null
		};
	},
	methods: {
		async getUserAccount(){
			const accessToken = await this.$auth.getTokenSilently();
			const { data } = await axios.get(`/api/users?name=${this.$auth.user.name}&email=${this.$auth.user.email}`, { headers: { Authorization: `Bearer ${accessToken}`} } );
			this.user = data.user;
		},
		logout() {
            this.$auth.logout();
            this.$router.push({ path: "/" });
			
        }
	}
};
</script>
