/* eslint-disable vue/valid-v-model */
<template>
    <div>
        <h2 class="text-2xl leading-7 font-semibold">
            Sync your crypto holdings with leading exchanges
        </h2>
        <p class="mt-3 mb-4 text-gray-600">
            Add credentials for the exchanges you have holdings and Mioport will automatically aggregate them for you.
        </p>
		<div class="">
			<Exchanges :user="user" v-if="user" />	
		</div>
    </div>
    
</template>

<script>

import axios from "axios";
import Exchanges from "@/components/Exchanges";

export default {
	name: "connects",
	components: {
		Exchanges
	},
    props: ["user"],
	mounted(){
		this.platforms = this.user.platforms;
	},
	data: function () {
		return {
			activePlatform: "binance", // binance | coinbase | bitkub
			platforms: {},
			timezone: "",
			availableTimezones: []
		};
	},
	methods: {
		switchTab: async function(tab){
			this.activePlatform = tab;
		},
		handleSubmitCredentials: async function(){
			const accessToken = await this.$auth.getTokenSilently();
			const { data } 	  = await axios.put(`/api/users`, { platforms: {...this.platforms}, timezone: this.timezone }, { headers: { Authorization: `Bearer ${accessToken}`} });
			if(data.user){
				this.$toast.open({
					message: "Information updated successfully!",
					type: "success",
					position: "bottom",
					duration: 5000
				});
			}else{
				this.$toast.open({
					message: "There was an issue updating the information.",
					type: "error",
					position: "bottom",
					duration: 5000
				});
			}
		}
	}
};
</script>

<style scoped>
	.grayscale{
		filter: grayscale(1);
	}
</style>