<template>
	<div class="relative flex  min-h-screen bg-gray-100 sm:pt-0">
		<div class="md:w-1/2 mx-auto md:px-6 lg:px-8 mt-24 p-1">
			<Logo />
			<Navigation />
			<div class="mt-4 bg-white overflow-hidden shadow sm:rounded-lg p-6">
				<h2 class="text-2xl leading-7 font-semibold">
					User settings and plan
				</h2>
				<div class="mt-4 pt-4 text-gray-800 border-t border-dashed" v-if="user">
					<div class="w-full">
						<div class="w-full">
							<div class="mb-5">
								<label for="first-name" class="block text-sm font-medium text-gray-700">
									Target Portfolio Number
								</label>
								<div class="mt-1">
									<input name="targetPortfolioNumber" id="" v-model="targetPortfolioNumber" class="px-4 h-10 text-md focus:ring-blue-500 focus:border-blue-500 block w-full text-gray-900 rounded-md border border-gray-300" />
								</div>
								<!-- <span class="text-gray-400 font-regular text-sm">e.g.: 089 123 4567</span> -->
							</div>
						</div>
						<div class="mb-5">
							<label for="first-name" class="block text-sm font-medium text-gray-700">
								Base Currency
							</label>
							<div class="mt-1">
								<select name="baseCurrency" id="" v-model="baseCurrency" class="px-4 h-10 text-md focus:ring-blue-500 focus:border-blue-500 block w-full text-gray-900 rounded-md border border-gray-300">
									<option value="usd">USD</option>
									<option value="thb">THB</option>
								</select>
							</div>
							<!-- <span class="text-gray-400 font-regular text-sm">Time displayed will be based on this selection.</span> -->
						</div>
					</div>
					<div class="w-full border-b border-dashed mt-5">
						<div class="mb-5">
							<label for="" class="block text-sm font-medium text-gray-700">
								Default Price Source
							</label>
							<div class="mt-1">
								<select name="priceSource" id="" v-model="priceSource" class="px-4 h-10 text-md focus:ring-blue-500 focus:border-blue-500 block w-full text-gray-900 rounded-md border border-gray-300">
									<option value="cmc">Coin Market Cap.</option>
									<option value="cryptoCompare">Crypto Compare</option>
								</select>
							</div>
							<!-- <span class="text-gray-400 font-regular text-sm">Time displayed will be based on this selection.</span> -->
						</div>
					</div>
					<div class="w-full border-b border-dashed mt-5">
						<div class="mb-5">
							<label for="first-name" class="block text-sm font-medium text-gray-700">
								Mobile Number
							</label>
							<div class="mt-1">
								<input name="mobile" id="" v-model="mobile" class="px-4 h-10 text-md focus:ring-blue-500 focus:border-blue-500 block w-full text-gray-900 rounded-md border border-gray-300" />
							</div>
							<span class="text-gray-400 font-regular text-sm">e.g.: 089 123 4567</span>
						</div>
						<div class="mb-5">
							<label for="first-name" class="block text-sm font-medium text-gray-700">
								Timezone
							</label>
							<div class="mt-1">
								<select name="timezone" id="" v-model="timezone" class="px-4 h-10 text-md focus:ring-blue-500 focus:border-blue-500 block w-full text-gray-900 rounded-md border border-gray-300">
									<option v-for="timezone in availableTimezones" :value="timezone" :key="timezone">{{timezone}}</option>
								</select>
							</div>
							<span class="text-gray-400 font-regular text-sm">Time displayed will be based on this selection.</span>
						</div>
					</div>
					
					<div class="text-right">
						<div class="pt-5">
							<div class="flex justify-end">
								<button @click="handleSubmitCredentials" type="submit" class="ml-3 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-4 bg-white overflow-hidden shadow sm:rounded-lg p-6">
				<Subscription :user="user" />
			</div>
			<div class="flex justify-center pt-4 space-x-2"></div>
			<div class="text-gray-400 text-center text-sm">
				All Rights Reserved. MioPort. 2021.
			</div>
		</div>
	</div>
</template>

<script>

import axios from "axios";

import Logo from "../components/Logo.vue";
import Navigation from "../components/Navigation.vue";
import Subscription from "../components/Subscription";
import { timezones } from "../data/timezones";

export default {
	name: "setting",
	components: {
		Logo,
        Navigation,
		Subscription
	},
	mounted() {
		this.$nextTick(() => {
			this.getUserAccount();
		});
		this.availableTimezones = timezones.sort();
	},
	data: function () {
		return {
			user: null,
			timezone: "",
			mobile: "",
			targetPortfolioNumber: 0,
			baseCurrency: 'usd',
			priceSource: 'cmc',
			availableTimezones: []
		};
	},
	methods: {
		async getUserAccount(){
			const accessToken = await this.$auth.getTokenSilently();
			const { data } 	  = await axios.get(`/api/users?name=${this.$auth.user.name}&email=${this.$auth.user.email}`, { headers: { Authorization: `Bearer ${accessToken}`} } );
			this.user 		  = data.user;
			this.timezone 	  = data.user.timezone;
			this.mobile 	  = data.user.mobile;
		},
		handleSubmitCredentials: async function(){
			const accessToken = await this.$auth.getTokenSilently();
			const { data } = await axios.put(`/api/users`, 
				{ 
					timezone: this.timezone, mobile: this.mobile, targetPortfolioNumber: this.targetPortfolioNumber, baseCurrency: this.baseCurrency, priceSource: this.priceSource
				}, { headers: { Authorization: `Bearer ${accessToken}`} });
			if(data.user){
				this.$toast.open({
					message: "Information updated successfully!",
					type: "success",
					position: "bottom",
					duration: 5000
				});
			}else{
				this.$toast.open({
					message: "There was an issue updating the information.",
					type: "error",
					position: "bottom",
					duration: 5000
				});
			}
		}
	}
};
</script>
