<template>
	<div id="app" class="">
		<router-view/>
	</div>
</template>

<script>
export default {
};
</script>
