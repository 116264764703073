import Vue from "vue";
import Router from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Connect from "../views/Connect.vue";
import Settings from "../views/Settings.vue";

import { authGuard } from "../auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "bg-gray-200 text-gray-800",
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/",
      name: "home",
      component: Home,
      beforeEnter: authGuard
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      beforeEnter: authGuard
    },
    {
      path: "/connect",
      name: "connect",
      component: Connect,
      beforeEnter: authGuard
    }
  ]
});

export default router;
