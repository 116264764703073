<template>
    <div class="mt-16">
        
        <div class="sm:block">
            <a @click="handleToggleNavigation" class="block md:hidden border mb-2 text-center block text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md">Menu</a>
            <nav class="block" aria-label="Tabs" v-if="isNavigationOpen">
                <!-- Current: "bg-gray-200 text-gray-800", Default: "text-gray-600 hover:text-gray-800" -->
                <router-link exact to="/" class="block md:inline-block text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md">Home</router-link>
                <router-link exact to="/connect" class="block md:inline-block md:ml-3 text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md">Connect</router-link>
                <router-link exact to="/settings" class="block md:inline-block md:ml-3 text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md">Settings</router-link>
                <a href="#" @click="logout()" class="block md:inline-block md:float-right md:ml-3 text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md">
                    Logout
                </a>
            </nav>
            
        </div>
    </div>
</template>

<script>
export default {
	name: "navigation",
    data: function () {
		return {
			isNavigationOpen: true
		};
	},
	methods: {
		logout() {
            this.$auth.logout({returnTo: process.env.VUE_APP_BASE_URL});
        },
        handleToggleNavigation(){
            this.isNavigationOpen = (this.isNavigationOpen) ? false : true;
        }
	}
};
</script>